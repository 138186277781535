import React from "react";

const Footer = ({ className, ...rest }) => {
  return (
    <>
    </>
  );
};

export default Footer;
