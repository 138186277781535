import React, { useState } from "react";
import { Link } from "gatsby";
import SimpleReactLightbox from 'simple-react-lightbox'

const GlobalContext = React.createContext();

export const themeConfigDefault = {
  bodyDark: false,
  headerDark: false,
  footerDark: false,
  headerStyle: "style1", //style2, style3
  headerFluid: true,
  headerButton: (
    <>
      <Link to="https://app.digma.io?mode=login" className="btn text-white btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">
        Try for free
      </Link>
    </>
  ),
  headerClassName: "site-header--menu-logo-middle",
  pageClassName:"",
  headerSocial: false,
  headerLogoClassName: "",
  footerStyle: "style1",
  footerClassName: "",
  version:"default",
};

const GlobalProvider = ({ children }) => {
  const [theme, setTheme] = useState(themeConfigDefault);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);

  const changeTheme = (themeConfig = themeConfigDefault) => {
    setTheme({
      ...theme,
      ...themeConfig,
    });
  };

  const toggleVideoModal = () => {
    setVideoModalVisible(!videoModalVisible);
  };

  const toggleOffCanvas = () => {
    setVisibleOffCanvas(!visibleOffCanvas);
  };

  const closeOffCanvas = () => {
    setVisibleOffCanvas(false);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme,
        changeTheme,
        videoModalVisible,
        toggleVideoModal,
        visibleOffCanvas,
        toggleOffCanvas,
        closeOffCanvas,
      }}
    >
      <SimpleReactLightbox>
        {children}
      </SimpleReactLightbox>
      <div class="digma-popup" data-popupid="B2FFF8A4-9043-4999-82C3-F7C44981E565"/>
      <div class="digma-popup" data-popupid="7AD7A760-E52A-4691-A996-DACC3F9311C3"/>
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalProvider };
