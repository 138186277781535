import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";
// gatsby-browser.js


export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);

// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

// export const onRouteUpdate = ({ location }, pluginOptions = {}) => {
 
//   window.reload = () => {
//     addScript(`https://cdn.digma.io/dist/digma.min.2.0.0.js?v=${location.pathname}`)
//   }
//   return null
// }