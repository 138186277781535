import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"

import logoB from "../../assets/image/logo.png";
import logoW from "../../assets/image/logo.png";

const Logo = ({ className = "", ...rest }) => {
  return (
    <AniLink duration={1}  className={`${className}`} {...rest} paintDrip hex="#2196f3" to="/">
      <img src={logoB} alt="Digma Logo" className="w-100 light-version-logo" />
      <img src={logoW} alt="Digma Logo" className="w-100 dark-version-logo" />
    </AniLink>
  );
};

export default Logo;
