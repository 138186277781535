import React, { useEffect, useState } from 'react'
import styles from './go-top-button.module.scss'


const GoTopButton = () => {
    const [ showGoTop, setShowGoTop ] = useState( false )

const handleVisibleButton = () => {
    setShowGoTop( window.pageYOffset > 50 )
}

const handleScrollUp = () => {
    window.scrollTo( { left: 0, top: 0, behavior: 'smooth' } )
}

useEffect( () => {
    window.addEventListener( 'scroll', handleVisibleButton )
}, [] )

return (
    <div className={ showGoTop ? '' : styles.goTopHidden } tabIndex={0} role="button" onClick={ handleScrollUp } onKeyDown={handleScrollUp}>
        <button type={'button'} className={ styles.goTop }>
            <i className="goTop__text fas fa-chevron-up" />
        </button>
    </div>
)
}

export default GoTopButton