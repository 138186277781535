export const menuItems = [
    {name: "/digital-asset-management", label: "Digital Asset Management", color:"#2196f3"},
    {name: "/qr-codes", label: "Dynamic QR Codes", color:"#e80ad2"},
    {name: "/", label: "Interactive Media", color:"#2196f3",
        items: [
        { name: "/popups", label: "Pop-ups", color:"#25b401" },
        {name: "/interactive-images", label: "Images", color:"#ff9b04"},
        {name: "/interactive-video-player", label: "Videos", color:"#2196f3"}

      ],},
    {name: "/pricing", label: "Pricing", color:"#2196f3"},
];
