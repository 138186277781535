import React from "react";
import {Link} from "gatsby";
import Footer1 from "./Footer1";
import Footer2 from "./Footer2";
import Footer3 from "./Footer3";
import Footer4 from "./Footer4";
import Footer5 from "./Footer5";
import Footer6 from "./Footer6";
import FooterDigma from "./FooterDigma";
import FooterNone from "./FooterNone";
import CookieConsent from "react-cookie-consent";


const Footer = ({ className = "", style, ...rest }) => {
  let FooterEl = Footer1;

  if (style === "style2") {
    FooterEl = Footer2;
  }
  if (style === "style3") {
    FooterEl = Footer3;
  }
  if (style === "style4") {
    FooterEl = Footer4;
  }
  if (style === "style5") {
    FooterEl = Footer5;
  }
  if (style === "style6") {
    FooterEl = Footer6;
  }
  if (style === "digma") {
    FooterEl = FooterDigma;
  } 
  if (style === "none") {
    FooterEl = FooterNone;
  }    

  return (
    <>
      <FooterEl className={className} {...rest} />
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Allow"
        declineButtonText="Decline"
        cookieName="myAwesomeCookieName2"
        style={{ width:"375px",right:"10px", left:"unset", zIndex:"1000000" }}
        declineButtonStyle={{ background: "#2196F3", color: "#fff", fontSize: "13px" }}
        buttonStyle={{ background: "#0544de", color: "#fff", fontSize: "13px" }}
        expires={150}
        >
        <h1 className="font-size-8">Cookies &amp; Privacy{" "}</h1>
        <span style={{ fontSize: "15px", color:"#1d293f" }}>Cookies enable you to use shopping carts and to personalize your experience on our sites, tell us which parts of our websites people have visited, help us measure the effectiveness of ads and web searches, and give us insights into user behavior so we can improve our communications and products. </span>
        <Link to="/privacy-policy">More Information</Link>
      </CookieConsent>      
    </>
  );
};

export default Footer;
