import React from 'react';
import { Link } from 'gatsby';
import 'reactjs-popup/dist/index.css';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import imgLB from '../../assets/image/logo.png';
import imgLW from '../../assets/image/logo-white.png';
import { Helmet } from 'react-helmet';

const Footer = ({ className, ...rest }) => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>
      </Helmet>
      <div
        className={`bg-dark-cloud relative pt-15 pb-9 ${className}`}
        {...rest}
      >
        <div className="container">
          <div className="pb-5 pb-lg-12">
            <div className="row justify-content-lg-between">
              {/* Brand Logo */}
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-6 col-xs-7">
                <div className="brand-logo mb-10 mb-lg-0 dark-mode-texts pr-lg-10 pr-xl-0 pt-lg-3">
                  <Link to="/#">
                    <img
                      className="mx-auto mx-0 light-version-logo default-logo"
                      src={imgLB}
                      alt="Digma Logo"
                    />
                    <img
                      src={imgLW}
                      alt="Digma Logo"
                      className="dark-version-logo mx-auto mx-0"
                    />
                  </Link>
                  <p className="text-white font-size-5 mb-0 mt-8 mt-lg-10">
                    Make your content interactive
                  </p>
                </div>
              </div>
              {/* End Brand Logo */}
              <div className="col-xl-8 col-md-7 offset-lg-1">
                <div className="row">
                  {/* Single Widgets */}
                  <div className="col-sm-4 col-xs-6">
                    <div className="mb-10 mb-lg-0">
                      <h4 className="font-size-5 font-weight-normal mb-1 mb-md-8 text-bali-gray">
                        Products
                      </h4>
                      <ul className="list-unstyled">
                      <li className="mb-0 mb-md-4">
                          <AniLink
                            className="font-size-5 text-white gr-hover-text-dodger-blue-1"
                            paintDrip
                            hex="#2196f3"
                            to="/digital-asset-management"
                          >
                            Digital Asset Management
                          </AniLink>
                        </li>       
                        <li className="mb-0 mb-md-4">
                          <AniLink
                            className="font-size-5 text-white gr-hover-text-dodger-blue-1"
                            paintDrip
                            hex="#e80ad2"
                            to="/qr-codes"
                          >
                            Dynamic QR Codes
                          </AniLink>
                        </li>          
                        <li className="mb-0 mb-md-4">
                          <AniLink
                            className="font-size-5 text-white gr-hover-text-dodger-blue-1"
                            paintDrip
                            hex="#25b401"
                            to="/popups"
                          >
                            Popups
                          </AniLink>
                        </li>    
                        <li className="mb-0 mb-md-4">
                          <AniLink
                            className="font-size-5 text-white gr-hover-text-dodger-blue-1"
                            paintDrip
                            hex="#ff9b04"
                            to="/interactive-images"
                          >
                            Images
                          </AniLink>
                        </li>                                                                           
                      <li className="mb-0 mb-md-4">
                          <AniLink
                            className="font-size-5 text-white gr-hover-text-dodger-blue-1"
                            paintDrip
                            hex="#2196f3"
                            to="/interactive-video-player"
                          >
                            Videos
                          </AniLink>
                        </li>



                      </ul>
                    </div>
                  </div>
                  {/* End Single Widgets */}
                  {/* Single Widgets */}
                  <div className="col-sm-4 col-xs-6">
                    <div className="mb-10 mb-lg-0">
                      <h4 className="font-size-5 font-weight-normal mb-1 mb-md-8 text-bali-gray">
                        About
                      </h4>
                      <ul className="list-unstyled">
                        {/* <li className="mb-0 mb-md-4">
                          <Link
                            to="/info/cms"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            CMS
                          </Link>
                        </li>        */}
                        <li className="mb-0 mb-md-4">
                          <Link
                            to="/project-assistance"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            Project Assistance
                          </Link>
                        </li>
                        <li className="mb-0 mb-md-4">
                          <Link
                            to="/info/integration"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            Integration
                          </Link>
                        </li>
                        {/* <li className="mb-0 mb-md-4">
                          <Link
                            to="/info/faq"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            FAQ
                          </Link>
                        </li>                                                                      */}
                        <li className="mb-0 mb-md-4">
                          <Link
                            to="/wordpressplugin"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            Wordpress Plugin
                          </Link>
                        </li>
                        <li className="mb-0 mb-md-4">
                          <Link
                            to="/info/partners"
                            className="font-size-5 text-lily-white gr-hover-text-dodger-blue-1"
                          >
                            Partners
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widgets */}
                  {/* Single Widgets */}
                  <div className="col-sm-4 col-xs-8">
                    <div className="mb-10 mb-lg-0 mr-xl-12">
                      <h4 className="font-size-5 font-weight-normal mb-1 mb-md-8 text-bali-gray">
                        Contact
                      </h4>
                      <ul className="list-unstyled">
                        <li className="mb-0 mb-md-4">
                          <a
                            className="font-size-5 d-block text-white mb-0"
                            href="mailto:sales@digma.io"
                          >
                            Email Direct
                          </a>
                        </li>
                        <li className="mb-0 mb-md-4">
                          <Link
                            className="font-size-5 d-block text-white mb-0"
                            to="/contact"
                          >
                            Contact Form
                          </Link>
                        </li>
                        <li className="mb-0 mb-md-4">
                          <a
                            className="font-size-5 d-block text-white mb-0"
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/+447376367830?text=I%27m%20interested%20in%20an%20interactive%20video%20"
                          >
                            WhatsApp Chat
                          </a>
                        </li>
                        <li className="mb-0 mb-md-4">
                          <Link
                            className="font-size-5 d-block text-white mb-0"
                            to="/book-a-meeting"
                          >
                            Book A Meeting
                          </Link>{' '}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* End Single Widgets */}
                </div>
              </div>
            </div>
          </div>
          <div
            class="trustpilot-widget pb-7"
            data-locale="en-GB"
            data-template-id="5419b6a8b0d04a076446a9ad"
            data-businessunit-id="5faa8bee8413750001b3ca5a"
            data-style-height="24px"
            data-style-width="100%"
            data-theme="dark"
            data-min-review-count="10"
          >
            <a
              href="https://uk.trustpilot.com/review/digma.io"
              target="_blank"
              rel="noreferrer"
            >
              Trustpilot
            </a>
          </div>
          <div className="text-center text-lg-left border-top border-default-color dark-mode-texts d-flex align-items-center justify-content-center justify-content-sm-between flex-column flex-sm-row flex-wrap pt-7">
            <div className="mb-10 mb-sm-0">
              <div className="shadow-3 rounded-4" style={{ color: '#fff' }}>
                &copy;Copyright Digital Media Advertising Ltd 2022 || LTD
                Company number 04244448
              </div>
            </div>
            <div className="">
              <ul className="d-flex align-items-center list-unstyled mb-0">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.facebook.com/Digma-109240754236552"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-9 px-3"
                  >
                    <i className="fab fa-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.youtube.com/channel/UC67zs7mFa06_qq-bIU53wuA"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-9 px-3"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/Digma_videos"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-9 px-3"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.instagram.com/digma.io/"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-9 px-3"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.linkedin.com/company/digma-interactive/"
                    className="pl-5 text-white gr-hover-text-dodger-blue-1 font-size-9 px-3"
                  >
                    <i className="fab fa-linkedin" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
